import React from 'react'
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useRef } from "react";
import { Orange } from './Elements';

export default function ThankYouModal({ open, setOpen, afterClose }) {
  const cancelButtonRef = useRef();
  function closeModal() {
    setOpen(false);

    // timeout for visual effect of closing the modal
    setTimeout(afterClose, 300)
  }

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-white bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <figure className="w-full h-1/2 bg-black">
                  <img
                    src="/images/thankyou.jpg"
                    alt=""
                    className="w-full h-full object-contain" />
                </figure>

                <div className="my-4 mx-6 text-gray-600">
                  <p className="my-1">
                    We will not charge you yet as we're still testing our financial model.
                  </p>
                  <p className="my-2">
                    For now you get 1-year <Orange>Hobby</Orange> plan for free.
                  </p>
                </div>

                <div className="m-2 text-center">
                  <button
                    type="button"
                    className="inline-flex justify-center px-8 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={closeModal}
                  >
                    OK
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
