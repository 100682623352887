import React from 'react'

export const Orange = ({ children }) => (
  <strong className="text-orange-700">{children}</strong>
)


export const Label = ({name, children}) => (
  <label htmlFor={name} className="block text-sm font-medium text-gray-700 py-2 mt-2">{children}</label>
)

export const OrangeButton = ({className = "", children, ...other}) => (
    <button
      className={`bg-orange-700 text-white py-1 px-8 text-lg tracking-wide rounded ${className}`}
      {...other}
    >
      {children}
    </button>
)
