import React, { useEffect } from 'react';
import { RecoilRoot, useRecoilValue, useRecoilState } from 'recoil';
import { checkDomainQuery, showcasesQuery, domainName } from './domainState'
import SidebarDomainEntry from './SidebarDomainEntry';
import dayjs from 'dayjs'
import last from 'lodash/fp/last'
import pruned from 'pruned';

import Link from '../Link';
import Linkout from '../Linkout';
import { Orange } from './Elements';
import PurchaseFlow from './PurchaseFlow';

import { setTrackingDomainName } from './tracking'

const reportUrl = "https://deepnote.com/@--ae8a/SSL-Pulse-report-fBK137yIQk-cMkKM6iqyKA"

function classifyExpiration(check) {
  const expiresOn = dayjs(check.ssl_expires_on)

  let tier = "UP_TO_DATE"
  if (expiresOn.isBefore(dayjs().add(1, 'month'))) { tier = "EXPIRES_SOON" }
  if (expiresOn.isBefore(dayjs().add(4, 'day'))) { tier = "EXPIRES_REALLY_SOON" }
  if (expiresOn.isBefore(dayjs())) { tier = "EXPIRED" }

  return tier
}

const WarningMessage = ({ instructionsUrl }) => (
  <div className="flex my-8 py-8 pl-1 w-5/6 bg-transparent">
    <figure className='w-20 min-w-5 h-16' >
      <img
        src='/images/warning_stop.png'
        alt='warning'
        className='w-full h-full'
      />
    </figure>

    <div className='text-base font-bold leading-5 tracking-wider pt-1'>
      <p>If you haven’t completed the renewal by the expiration date,
        your website displays an error message and won't be accessible to your visitors.</p>
      {/* Conditional instructions: either {instructions_url} or generic label: */}
      {instructionsUrl ?
        <p className="mt-4">
          Here are &nbsp;
          <Linkout className="underline" href={instructionsUrl}>
            renewal instructions
          </Linkout>
          &nbsp; for your website developers
        </p> : <p className="mt-4">
          To renew the certificate please contact your website developers.
        </p>
      }
    </div>
  </div>
)

// "The current certificate is issued by XXX and is up to date"
const UpToDateArticle = ({ check }) => {
  const tier = classifyExpiration(check)
  const { instructions_url } = check

  return (
    <article className="font-light">
      <h1 className="text-5xl mb-6 font-bold tracking-wider text-orange-700 leading-12">
        <img className="hidden sm:inline-block h-20" src="/images/ssl-circle.png" alt="" />
        <span className="mb-6 sm:ml-3">{check.name} uses SSL</span>
      </h1>

      {check.daily_uniques && <div className="my-6">
        <Linkout href={`https://rankchart.org/site/${check.name}/`}>rankchart.org’s</Linkout> estimate
        of the website’s traffic is <strong className="font-semibold">{pruned.Number(check.daily_uniques)} unique visitors</strong> per day.
      </div>}

      {check.issuer_org &&
        <p className="my-2">
          The current certificate is issued by
          <strong className="font-bold"> {check.issuer_org} </strong>
          and <CurrentDomainStatus check={check} tier={tier} />
        </p>
      }

      {tier !== "UP_TO_DATE" && <WarningMessage instructionsUrl={instructions_url} />}
    </article>
  )
}

// Support us. Protect your website.
const SupportUsSection = () => (
  <section className="font-light">
    <h1 className="text-5xl font-bold tracking-wider text-orange-700 text-left mb-8 leading-12">
      Support us
      <span className="hidden sm:inline">. Protect your website.</span>
    </h1>

    <article id="Motivation">
      <p className="my-6">
        Sign up for the <Orange>Hobby</Orange> plan - and we’ll send emails & SMS alerts 15/10/5/3/2/1 days ahead of SSL expiration.
      </p>

      <p className="my-6">
        Consider the <Orange>Pro</Orange> plan - and our team will take over the situation and find the way to contact you in time if automated notifications don’t help.
      </p>
    </article>
  </section>
)

const infoCircleBgImage = {
  'background': 'url("/images/info-circle.png") top left no-repeat',
  'backgroundSize': '46px 46px'
}

const NotSubscribedSections = () => (
  <>
    <section className="pt-12 md:pt-10">
      <SupportUsSection />
    </section>

    <section className="py-2 md:py-8">
      <PurchaseFlow />
    </section>
  </>
)

const SubscribedSections = () => {
  const { name, subscription_valid_till } = useRecoilValue(checkDomainQuery)
  const till = dayjs(subscription_valid_till).format('MMMM DD, YYYY')

  return (
    <section className="pt-12 md:pt-10">
      <h1 className="text-5xl font-bold tracking-wider text-orange-700 text-left">
        Protected by SSL Pulse
      </h1>

      <article id="SubscriptionDetails" className="py-2 md:py-8 font-light">
        <p className="my-4">
          The <Orange>Hobby</Orange> plan is active until <strong>{till}</strong>. <br />
          We’re monitoring {name} and will send
          emails &amp; SMS alerts 15/10/5/3/2/1 days ahead of SSL expiration.
        </p>

        <p className="my-6">
          In case of any questions or inquiries please email us
          at <a className="text-blue-600 underline" href="mailto:hello@sslpulse.org">hello@sslpulse.org</a>
        </p>

        <p className="my-6">
          Thanks for supporting sslpulse.org!
        </p>
      </article>
    </section>
  )
}

const ShowcasesList = () => {
  const showcases = useRecoilValue(showcasesQuery)

  return (
    <ul className="pt-2">
      {showcases.map((item, ind) => (
        <SidebarDomainEntry key={ind} item={item} />
      ))}
    </ul>
  )
}


const CurrentDomainStatus = ({ check, tier }) => {
  const expiresOn = dayjs(check.ssl_expires_on)
  const expiresOnFmt = expiresOn.format("MMMM DD, YYYY")

  const TIERS = {
    "UP_TO_DATE": () => (
      <>
        <strong className="text-green-700 font-bold"> is up to date </strong>
        (expires on {expiresOnFmt})
      </>
    ),

    "EXPIRES_SOON": () => (
      <strong className="text-yellow-700 font-bold"> expires soon (on {expiresOnFmt})</strong>
    ),

    "EXPIRES_REALLY_SOON": () => (
      <strong className="text-red-700 font-bold"> expires really soon (on {expiresOnFmt}) </strong>
    ),

    "EXPIRED": () => (
      <strong className="text-red-700 font-bold"> has expired on {expiresOnFmt} </strong>
    )
  }

  return TIERS[tier]()
}

const InitDomainName = () => {
  const [name, setName] = useRecoilState(domainName)

  useEffect(() => {
    if (!name) {
      const domainName = last(document.location.pathname.split("/"))
      setName(domainName)
    }
  })

  return (<></>)
}

const InitTracking = () => {
  const check = useRecoilValue(checkDomainQuery)

  useEffect(() => {
    if (check && check.id) {
      setTrackingDomainName(check)
    }
  }, [check])

  return (<></>)
}
const Content = () => {
  const check = useRecoilValue(checkDomainQuery)
  const subscribed = check.subscription_plan

  return (
    <div className="grid grid-cols-1 lg:grid-cols-4 text-xl">
      <div className="max-w-244 mr-8 pl-2 sm:pl-8 ml-auto lg:col-span-3 mb-10">
        <section className="pt-5 lg:pt-10">
          <UpToDateArticle check={check} />
        </section>

        {subscribed && <SubscribedSections />}
        {!subscribed && <NotSubscribedSections />}
      </div>


      {/* Sidebar */}
      <div className='bg-gray-50 border-l border-blue-100 pt-12 pl-8'>
        <div>
          <div style={infoCircleBgImage} className='text-blue-650 pl-16 font-bold tracking-wider'>
            <h4 className='text-lg leading-5'>2% TOP WEBSITES*<br />MISS SSL RENEWAL</h4>
            <div className="text-blue-650 text-xs leading-4 pt-1.5">
              <span>*&nbsp;</span>
              <Link href={reportUrl} className="underline">Learn how we measure</Link>
            </div>
          </div>
          <React.Suspense fallback={<div />}>
            <ShowcasesList />
          </React.Suspense>
        </div>
      </div>
    </div>
  )
}

const CheckYourDomain = () => (
  <RecoilRoot>
    <InitDomainName />
    <React.Suspense fallback={<div className="tracking-wide text-gray-500 text-xl text-center my-72">Loading...</div>}>
      <InitTracking />
      <Content />
    </React.Suspense>
  </RecoilRoot>
)

export default CheckYourDomain;
