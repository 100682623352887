import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { Label, Orange, OrangeButton } from './Elements'
import ThankYouModal from './ThankYouModal'

import { useSetRecoilState, useRecoilValue } from 'recoil'
import { submitted, domainName } from './domainState'
import { createSubscription } from './api'
import { logEvent } from './tracking'

const benefits = {
  "hobby": [
    "SMS & email alerts",
    "5-years coverage"
  ],
  "pro": [
    "SMS & email alerts",
    "5-years coverage",
    "alerts by real humans",
    "email support"
  ]
}

const planNames = {
  "hobby": "Hobby",
  "pro": "Pro"
}

const prices = {
  "hobby": 9,
  "pro": 99
}

// helper styles
const brownStyle = { color: "#CEB1A6" }
const pullUpStyle = { "position": "relative", "top": -2 }

const PlanBox = ({ plan, className, setCurrentPlan }) => {
  const ctaClicked = () => {
    logEvent("AddToCart", { plan })
    setCurrentPlan(plan)
  }

  return (
    <div className={`${className} w-1/2 border-gray-300`}>
      <Orange>{planNames[plan]}</Orange>
      <p className="my-6">
        <strong className="font-bold text-orange-100 text-3xl tracking-wider">${prices[plan]}</strong>
        <span className="block sm:inline text-gray-400 tracking-widest text-sm" style={pullUpStyle}> (one-time)</span>
      </p>
      <OrangeButton type="button" onClick={ctaClicked}>
        Buy <span className="hidden sm:inline"> {planNames[plan]}</span>
      </OrangeButton>

      <p className="hidden sm:block mt-7 mb-2 text-xs" style={brownStyle}>WHAT'S INCLUDED</p>
      <p className="block sm:hidden mt-7 mb-2 text-xs" style={brownStyle}>INCLUDES</p>

      <ul className="tracking-wider text-sm sm:text-xl">
        {benefits[plan].map(item => (
          <li className="my-1" key={item}><span className="text-orange-700 pr-1">✓</span>{item}</li>
        ))}
      </ul>
    </div >
  )
}

const PlansSelector = ({ setCurrentPlan }) => (
  <div id="PurchaseForm" className="">
    <h3 className="" style={brownStyle}>PRICING PLANS</h3>

    <div className="lg:w-124 mx-auto flex flex-row mt-3">
      <PlanBox plan="hobby" setCurrentPlan={setCurrentPlan} />
      <PlanBox plan="pro" className="border-l pl-9" setCurrentPlan={setCurrentPlan} />
    </div>
  </div>
)

const ContactsForm = ({ plan, setCurrentPlan, onPay }) => {
  const { register, setFocus, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => setFocus("email"), [setFocus])

  return (<form id="ContactsForm" onSubmit={handleSubmit(onPay)}>
    <h3 style={brownStyle} className="text-xs sm:text-xl">
      <button type="button" className="underline text-blue-500" onClick={() => { setCurrentPlan(null) }}>PRICING PLANS</button>
      <span className="px-2">&gt;</span>
      <strong className="text-bold text-gray-800">CONTACTS</strong>
      <span className="px-2">&gt;</span>
      <strong className="text-bold text-gray-300">PAYMENT</strong>
    </h3>

    <div className="sm:ml-24 my-3">
      <Label name="email">Email (for recipe and alerts)</Label>
      <input type="email" name="email" className="px-3 py-2 w-72 rounded border-gray-300" {...register("email", { required: true })} />
      {errors.email && <span className="ml-4 text-red-700 text-sm">Email is required</span>}

      <Label name="phone">Phone (for SMS alerts, please include country code)</Label>
      <input type="text" placeholder="+1-555-555-5555" name="phone" className="px-3 py-2 w-72 rounded border-gray-300" {...register("phone", { required: true })} />
      {errors.phone && <span className="ml-4 text-red-700 text-sm">Phone number is required</span>}

      {plan === "pro" && <>
        <Label name="extra">Additional contacts (for our staff)</Label>
        <textarea rows="5" placeholder="e.g. landline phone numbers, messengers, link to contact form etc" id="extra" {...register("extra", {})} className="px-3 py-2 w-full rounded border-gray-300" />
      </>}

      <div className="mt-6">
        <OrangeButton >Pay ${prices[plan]}</OrangeButton>
        <p className="mt-1 text-sm text-gray-400">One-time payment for 5-years coverage</p>
      </div>
    </div>
  </form>
  )
}

const PurchaseFlow = () => {
  const setSubmitted = useSetRecoilState(submitted)
  const name = useRecoilValue(domainName)
  const afterClose = () => setSubmitted(true)

  // modal & purchase flow states are local to only this component
  const [currentPlan, setCurrentPlan] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // this creates a Subscription record on the backend
  const onPay = async (data) => {
    try {
      logEvent('PurchaseConfirmation', { plan: currentPlan, $revenue: prices[currentPlan] })

      await createSubscription(name, { plan: currentPlan, ...data })
      setModalOpen(true)
    }
    catch (error) {
      // TODO: Rollbar
      alert("Something went wrong, we've been notified")
    }
  }

  if (currentPlan) {
    return <>
      <ContactsForm plan={currentPlan} setCurrentPlan={setCurrentPlan} onPay={onPay} />
      <ThankYouModal open={modalOpen} setOpen={setModalOpen} afterClose={afterClose} />
    </>
  } else {
    return <PlansSelector plan={currentPlan} setCurrentPlan={setCurrentPlan} />
  }
}

export default PurchaseFlow
