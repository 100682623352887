import React from 'react'
import dayjs from 'dayjs'
import Linkout from '../Linkout';

const SidebarDomainEntry = ({ item }) => {
  const { domain } = item
  const { colorClass, message } = analyzeItem(item)

  return (
    <li className="my-6">
      <p>
        <Linkout href={`https://${domain.name}`}
          rel="noreferrer"
          className={"text-lg tracking-widest font-bold leading-6 " + colorClass}>
          {domain.name}
        </Linkout>
      </p>

      <p className="text-gray-350 text-sm leading-6">{message}</p>
    </li>
  )
}

function analyzeItem({ duration_sec, expired_at, state }) {
  if (state === 'fixed') {
    return {
      colorClass: "text-green-600",
      message: `recovered after ${formatDuration(duration_sec)} outage`
    }
  }
  // else: expiration still in progress
  const agoSec = dayjs().diff(expired_at) / 1000
  return {
    colorClass: "text-red-600",
    message: `expired ${formatDuration(agoSec)} ago`
  }
}

function formatDuration(sec) {
  const h = (sec / 3600).toFixed(0)
  if (h >= 2) return `${h} hours`
  if (h === 1) return "1 hour"
  return `${(sec / 60).toFixed(0)} min`
}

export default SidebarDomainEntry
