import React from "react"
import { Router } from "@reach/router"
import Layout from '../components/layout/Layout';

// app sub-pages
import CheckYourDomain from "../components/app/CheckYourDomain"
import Default from "../components/app/Default"

const App = () => (
  <Layout header="normal">
    <Router basepath="/app">
      <CheckYourDomain path="/check/:domain" />
      <Default path="/" />
    </Router>
  </Layout>
)

export default App
