const enabled = () => typeof window.amplitude === 'object'
const amp = () => window.amplitude.getInstance()

export function logEvent(amplitudeEventType, amplitudeEventProperties) {
  if (enabled()) {
    amp().logEvent(amplitudeEventType, amplitudeEventProperties)
  } else {
    console.debug("logEvent [OFF]: ", amplitudeEventType, amplitudeEventProperties)
  }
}

export function setTrackingDomainName({ name, id }) {
  if (enabled()) {
    amp().setUserId("domain-" + id);

    const identify = new window.amplitude.Identify()
      .set('domain', name)
      .set('domain_id', id)
      .setOnce('orig_domain_id', id)

    amp().identify(identify);
  }
}
