// const API_ROOT = "http://localhost:4000"
const API_ROOT = "https://domaino-scrapper.herokuapp.com"

export async function checkDomain(domainName) {
  return get(`/api/check_domain/${domainName}`)
}

// LATER: pass domain name to make it more "personalized"
export async function getShowcases(_domainName) {
  return get(`/api/showcases`)
}

export async function createSubscription(domainName, formData) {
  return post(`/api/domains/${domainName}/subscriptions`, formData)
}

// PRIVATE

async function get(path) {
  return fetch(`${API_ROOT}${path}`)
    .then(response => response.json())
}

async function post(path, data = {}) {
  return fetch(`${API_ROOT}${path}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(response => response.json())
}
