import React from 'react';
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics';

const Linkout = ({ href = '', className = '', children }) => {
  if (!className.includes("text-")) {
    className = className + " text-blue-700 hover:text-blue-400"
  }

  return (
    <OutboundLink
      href={`${href}`}
      className={className}
      target="_blank"
      rel="noreferrer"
    >
      {children}
    </OutboundLink>
  );
}

export default Linkout;
