import {
  atom,
  selector,
} from 'recoil';

import { checkDomain, getShowcases } from './api'

const domainName = atom({
  key: "DOMAIN",
  default: null
})

// changing this to true makes an API call to reload the domain
const submitted = atom({
  key: "DOMAIN/SUBMITTED",
  default: false
})

const checkDomainQuery = selector({
  key: "DOMAIN/CHECK",
  get: async ({ get }) => {
    get(submitted)
    const name = get(domainName)
    return name && checkDomain(name)
  }
})

const showcasesQuery = selector({
  key: "DOMAIN/SHOWCASES",
  get: async ({ get }) => {
    const name = get(domainName)
    return name && getShowcases(name)
  }
})

export { submitted, domainName, checkDomainQuery, showcasesQuery }
